import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import styles from './Ranking.module.scss';
import { Avatar, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from '@chakra-ui/react';
import trophy from '../../assets/ranking/ranking-trofeu.png';

// Função para gerar URL do avatar
const getAvatarUrl = (name) => {
  const gender = isFemale(name) ? 'female' : 'male';
  return `https://randomuser.me/api/portraits/${gender === 'male' ? 'men' : 'women'}/${Math.floor(Math.random() * 100)}.jpg`;
};

// Função simples para determinar o gênero baseado no nome
const isFemale = (name) => {
  const femaleNames = ['Maria', 'Ana', 'Beatriz', 'Larissa', 'Fernanda'];
  return femaleNames.some(femaleName => name.includes(femaleName));
};

// Dados fictícios para o ranking
const rankingData = [
  { name: 'João Silva', score: 2500 },
  { name: 'Maria Oliveira', score: 2400 },
  { name: 'Carlos Souza', score: 2300 },
  { name: 'Ana Lima', score: 2200 },
  { name: 'Paulo Gomes', score: 2100 },
  { name: 'Beatriz Costa', score: 2000 },
  { name: 'Pedro Mendes', score: 1900 },
  { name: 'Larissa Pinto', score: 1800 },
  { name: 'Fernanda Santos', score: 1700 },
  { name: 'Lucas Ferreira', score: 1600 },
];

const Ranking = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasVisited, setHasVisited] = useState(false);

  useEffect(() => {
    // Mostrar o modal automaticamente quando a página for acessada
    if (!hasVisited) {
      onOpen();
      setHasVisited(true);
    }
  }, [hasVisited, onOpen]);

  return (
    <DashboardLayout>
      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent className={styles['modal-content']} bg={'#17161A'}>
          <ModalHeader color={'#fff'} textAlign="center">Bem-vindo ao Ranking Guitar Money!</ModalHeader>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <img src={trophy} alt="Troféu" className={styles['trofeu']} />
            <p>Veja como você pode subir no ranking:</p>
            <ol className={styles['timeline']}>
              <li>1. Toque músicas e ganhe pontos.</li>
              <li>2. Desafie outros jogadores.</li>
              <li>3. Acumule mais pontos para subir no ranking!</li>
            </ol>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" onClick={onClose}>
              Vamos lá!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Seção do Ranking */}
      <section className={styles['ranking-container']}>
        <div className={styles['container']}>
          <h2>Ranking Top 10</h2>
          <div className={styles["row-img"]}>
            <img src={trophy} alt="Troféu" className={styles['trofeu']} />
          </div>

          <div className={styles['ranking-card']}>
            <ul className={styles['ranking-list']}>
              {rankingData.map((user, index) => (
                <li key={user.name}>
                  <div className={styles['user-info']}>
                    <div
                      className={`${styles['user-photo']} ${index < 3 ? styles['top-3'] : ''
                        }`}
                    >
                      <Avatar size="2xl" name={user.name} src={getAvatarUrl(user.name)} />
                    </div>
                    <span className={styles['user-name']}>{user.name}</span>
                  </div>
                  <div className={styles['user-score']}>
                    <p>Notas Acertadas</p>
                    <b>{user.score}</b>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default Ranking;
