import React from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Button,
  VStack,
  HStack,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaEdit, FaTrash, FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import DashboardLayout from '../components/DashboardLayout';
import BackgroundCard1 from '../assets/img/BackgroundCard1.png';
import Chip from '../assets/img/Chip.png';

const Billing = () => {
  const cardBgColor = useColorModeValue('blue.500', 'blue.700');
  const cardTextColor = useColorModeValue('white', 'gray.200');
  const sectionBgColor = useColorModeValue('white', 'gray.800');
  const listItemBgColor = useColorModeValue('gray.100', 'gray.700');
  const buttonColorScheme = useColorModeValue('teal', 'teal');

  return (
    <DashboardLayout>
      <Box p={8} bg="gray.50" minH="100vh">
        <Text fontSize="2xl" fontWeight="bold" mb={6}>
          Billing
        </Text>

        <Grid templateColumns="repeat(12, 1fr)" gap={6} mb={10}>
          {/* Cartão de Crédito */}
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Box
              p="24px"
              borderRadius="lg"
              backgroundImage={`url(${BackgroundCard1})`}
              backgroundSize="cover"
              backgroundPosition="center"
              color="white"
              h="200px"
              position="relative"
              boxShadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Flex justify="space-between" align="center">
                <Text fontSize="md" fontWeight="bold">
                  Purity UI
                </Text>
                <Image
                  src={Chip}
                  alt="Chip Icon"
                  boxSize="30px"
                />
              </Flex>
              <Text fontSize="2xl" fontWeight="bold" mt={4}>
                7812 2139 0823 XXXX
              </Text>
              <Flex justify="space-between" mt={4}>
                <Text>VALID THRU: 12/24</Text>
                <Text>CVV: 09x</Text>
              </Flex>
            </Box>
          </GridItem>

          {/* Métodos de Pagamento */}
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Box bg={sectionBgColor} borderRadius="lg" boxShadow="sm" p={6} textAlign="center">
                <Image src={require('../assets/img/carteira.png')} alt="Salary Icon" boxSize="50px" mx="auto" mb={4} />
                <Text fontSize="lg" fontWeight="bold">
                  Salary
                </Text>
                <Text fontSize="xl" color="teal.500">
                  %2000
                </Text>
              </Box>
              <Box bg={sectionBgColor} borderRadius="lg" boxShadow="sm" p={6} textAlign="center">
                <Image src={require('../assets/img/paypal.png')} alt="Paypal Icon" boxSize="50px" mx="auto" mb={4} />
                <Text fontSize="lg" fontWeight="bold">
                  Paypal
                </Text>
                <Text fontSize="xl" color="teal.500">
                  %4550
                </Text>
              </Box>
            </Grid>

            <Box bg={sectionBgColor} borderRadius="lg" boxShadow="sm" p={6} mt={6}>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Payment Method
              </Text>
              <VStack spacing={4} align="stretch">
                <Flex align="center" justify="space-between">
                  <HStack>
                    <FaCcMastercard size="24px" color="red" />
                    <Text fontSize="lg">7812 2139 0823 XXXX</Text>
                  </HStack>
                  <IconButton aria-label="Edit" icon={<FaEdit />} />
                </Flex>
                <Flex align="center" justify="space-between">
                  <HStack>
                    <FaCcVisa size="24px" color="blue" />
                    <Text fontSize="lg">7812 2139 0823 XXXX</Text>
                  </HStack>
                  <IconButton aria-label="Edit" icon={<FaEdit />} />
                </Flex>
                <Button colorScheme={buttonColorScheme} w="full">
                  Add New Card
                </Button>
              </VStack>
            </Box>
          </GridItem>

          {/* Faturas */}
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Box bg={sectionBgColor} borderRadius="lg" boxShadow="sm" p={6} mb={6}>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Invoices
              </Text>
              <VStack spacing={4} align="stretch">
                {[
                  { date: 'March 01, 2020', id: '#MS-415646', amount: '$180' },
                  { date: 'February 10, 2020', id: '#RV-126749', amount: '$250' },
                  { date: 'April 05, 2020', id: '#FB-212562', amount: '$560' },
                  { date: 'June 25, 2019', id: '#QW-103578', amount: '$120' },
                  { date: 'March 01, 2019', id: '#AR-803481', amount: '$300' },
                ].map((invoice, index) => (
                  <Flex justify="space-between" align="center" key={index}>
                    <Box>
                      <Text>{invoice.date}</Text>
                      <Text color="gray.500">{invoice.id}</Text>
                    </Box>
                    <Text fontWeight="bold">{invoice.amount}</Text>
                    <Button size="sm" colorScheme={buttonColorScheme}>
                      PDF
                    </Button>
                  </Flex>
                ))}
              </VStack>
            </Box>
          </GridItem>

          {/* Billing Information */}
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <Box bg={sectionBgColor} borderRadius="lg" boxShadow="sm" p={6}>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Billing Information
              </Text>
              <VStack spacing={4} align="stretch">
                {[
                  {
                    name: 'Oliver Liam',
                    company: 'Viking Burrito',
                    email: 'oliver@burrito.com',
                    vat: 'FRB1235476',
                  },
                  {
                    name: 'Lucas Harper',
                    company: 'Stone Tech Zone',
                    email: 'lucas@techzone.com',
                    vat: 'EN234567893',
                  },
                  {
                    name: 'Ethan James',
                    company: 'Fiber Notion',
                    email: 'ethan@fiber.com',
                    vat: 'FRB1235476',
                  },
                ].map((billing, index) => (
                  <Flex align="center" justify="space-between" key={index} p={4} borderRadius="md" bg={listItemBgColor}>
                    <Box>
                      <Text fontWeight="bold">{billing.name}</Text>
                      <Text>Company Name: {billing.company}</Text>
                      <Text>Email: {billing.email}</Text>
                      <Text>VAT Number: {billing.vat}</Text>
                    </Box>
                    <HStack>
                      <IconButton aria-label="Edit" icon={<FaEdit />} />
                      <IconButton aria-label="Delete" icon={<FaTrash />} colorScheme="red" />
                    </HStack>
                  </Flex>
                ))}
              </VStack>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default Billing;
