import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom'; // Adicionando useNavigate
import styles from './ResultPage.module.scss';
import SquareCard from '../../components/SquareCard/SquareCard';
import trophyImg from '../../assets/result/trophy.png';

const ResultPage = () => {
  const location = useLocation(); // Hook para acessar a URL
  const navigate = useNavigate(); // Hook para navegar
  const searchParams = new URLSearchParams(location.search); // Extrai os parâmetros da URL

  const targetPoints = parseInt(searchParams.get('pontos') || '0'); // Captura os pontos da URL
  const totalEarned = parseFloat(searchParams.get('totalEarned') || '0'); // Captura o total conquistado da URL
  const targetAccuracy = parseInt(searchParams.get('accuracy') || '0'); // Captura a precisão da URL

  const [accuracy, setAccuracy] = useState(0); // Inicia o accuracy em 0
  const [points, setPoints] = useState(0); // Inicia os pontos em 0

  // Função para incrementar o accuracy até o valor alvo
  useEffect(() => {
    let currentAccuracy = 0;

    const intervalId = setInterval(() => {
      currentAccuracy += 1;
      setAccuracy(currentAccuracy);

      if (currentAccuracy >= targetAccuracy) {
        clearInterval(intervalId); // Para o intervalo quando o accuracy atingir o valor alvo
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [targetAccuracy]); // O intervalo será reiniciado se o valor alvo mudar

  // Função para incrementar os pontos até o valor alvo
  useEffect(() => {
    let currentPoints = 0;

    const pointsIntervalId = setInterval(() => {
      const increment = Math.ceil(targetPoints / 100); // Define o incremento dos pontos (ajustável)
      currentPoints += increment; // Adiciona o incremento aos pontos atuais
      if (currentPoints > targetPoints) {
        currentPoints = targetPoints; // Garante que não exceda o valor alvo
      }
      setPoints(currentPoints);

      if (currentPoints >= targetPoints) {
        clearInterval(pointsIntervalId); // Para o intervalo quando atingir o valor alvo de pontos
      }
    }, 20); // Atualiza a cada 20ms

    return () => clearInterval(pointsIntervalId); // Limpa o intervalo no desmontar do componente
  }, [targetPoints]);

  // Função para navegar para a rota "/play"
  const handleGoToPlay = () => {
    navigate('/play'); // Redireciona para a rota "/play"
  };

  return (
    <div className={styles['background-container']}>
      <div className={styles['container']}>
        <SquareCard className={styles['result-card']}>
          <h2 className={styles['result-title']}>Resultado!</h2>

          {/* Centralizando o circular progress com o troféu */}
          <Box className={styles['progress-container']}>
            <CircularProgress
              value={accuracy}
              size="200px"
              thickness="10px"
              color="#16D9F9"
              trackColor="#26252B"
              css={{
                strokeLinecap: 'round',
              }}
            >
              <CircularProgressLabel>
                <img className={styles['trophy-icon']} width="120px" src={trophyImg} alt="Trophy" />
              </CircularProgressLabel>
            </CircularProgress>
          </Box>

          <div className={styles['stats-container']}>
            <div className={styles['stats-item']}>
              <h3>{points}</h3>
              <p>Pontos</p>
            </div>
            <div className={styles['stats-item']}>
              <h3>{accuracy}%</h3>
              <p>Acertos</p>
            </div>
          </div>

          <Box className={styles['total-container']}>

            {totalEarned <= 0 ? (
              <p>
                Quase lá, uma hora você pega o jeito e sobe no ranking! Vamos tentar de novo!
              </p>
            ) : (
              <>
                <p>Total conquistado!</p>
                <h3 className={styles['total-earned']}>R$ {totalEarned.toFixed(2)}</h3>
              </>
            )}
          </Box>

          <Button colorScheme="orange" className={styles['menu-button']} onClick={handleGoToPlay}>
            Home
          </Button>
        </SquareCard>
      </div>
    </div>
  );
};

export default ResultPage;
