import React from 'react';
import { Box, Button, Text, Flex } from '@chakra-ui/react';
import { FaCopy, FaWhatsapp } from 'react-icons/fa';
import styles from './BalanceCard.module.scss'; // Importando o SCSS

interface BalanceCardProps {
  title: string;
  amount: string;
  onCopyLink?: () => void;
  onSendWhatsAppInvite?: () => void;
}

const BalanceCard: React.FC<BalanceCardProps> = ({ title, amount, onCopyLink, onSendWhatsAppInvite }) => {
  return (
    <Box className={styles.balanceCard}>
      <div className={styles.bgGray}>
        <Text className={styles.title}>{title}</Text>
        <Flex direction="column" align="start">
          <Text className={styles.amount}>{amount}</Text>
          {onCopyLink && onSendWhatsAppInvite && (
            <Flex className={styles.buttonGroup}>
              <Button onClick={onCopyLink} colorScheme="orange" leftIcon={<FaCopy />}>
                Link promotor
              </Button>
              <Button onClick={onSendWhatsAppInvite} colorScheme="whatsapp" leftIcon={<FaWhatsapp />}>
                Convidar
              </Button>
            </Flex>
          )}
        </Flex>
      </div>
    </Box>
  );
};

export default BalanceCard;
