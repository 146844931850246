import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';

const DashboardLayout = ({ children }) => {
  return (
    <Flex direction="column" minH="100vh" bg="brand.cardBg">
      <Header />
      <Flex flex="1" overflow="hidden">
        <Sidebar />
        <Box flex="1" bg="transparent" overflowY="auto">
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

export default DashboardLayout;
