import React, { useState } from 'react';
import { Box, Input, VStack, Text, useToast } from '@chakra-ui/react';
import SquareCard from '../../components/SquareCard/SquareCard'; // Importe o SquareCard
import './Login.scss';
import logoImg from '../../assets/logo.png';
import CustomButton from '../../components/CustomButton/CustomButton';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom'; // Para navegação
import { login as authServiceLogin } from '../../services/authService'; // Importa o serviço de login
import { useAuth } from '../../contexts/AuthContext'; // Importa o contexto de autenticação

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const data = { email, password };
      const response = await authServiceLogin(data);
      authLogin(response.token); // Armazena o token e define o estado como autenticado
      toast({
        title: 'Login realizado com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      navigate('/play'); // Redireciona para o perfil após o login
    } catch (error) {
      toast({
        title: 'Erro ao fazer login.',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="login-page">
      <SquareCard minHeight="auto" className="login-container">
        <VStack spacing={4} className='form-container'>
          <Box mb={4}>
            <img src={logoImg} alt="Logo" className='logo'/>
          </Box>
          <Input
            className="login-input"
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
            _placeholder={{ color: 'white' }} 
          />
          <Input
            className="login-input"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
            _placeholder={{ color: 'white' }} 
          />
          <CustomButton
            className="login-button"
            isLoading={isLoading}
            onClick={handleLogin}
          >
            JOGAR
          </CustomButton>
        </VStack>
        <Text mt={4} textAlign="center" className="login-footer">
          Não tem uma conta? <span onClick={() => navigate('/register')}>Registre agora</span> <ArrowRightIcon />
        </Text>
      </SquareCard>
    </Box>
  );
};

export default Login;
