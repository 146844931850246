export function formatCurrency(value: number ): string {
  // Transforma o valor em número, se não for
  let numericValue = typeof value === 'string' ? parseFloat(value) : value;

  // Divide por 100 para converter centavos em reais
  let realValue = numericValue / 100;

  // Formata o valor em reais com separadores de milhar e duas casas decimais
  let formattedValue = realValue
    .toFixed(2) // Garante duas casas decimais
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") // Adiciona pontos como separadores de milhar
    .replace(".", ","); // Substitui o ponto pela vírgula para separação decimal

  return `GC$ ${formattedValue}`;
}
