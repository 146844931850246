import React from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  useColorModeValue,
  Text,
  Progress,
  AvatarGroup,
  Avatar,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { FiCalendar, FiShoppingCart, FiSettings } from 'react-icons/fi';
import { BsWallet2 } from 'react-icons/bs';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Dashboard: React.FC = () => {
  const bg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header /> {/* Header centralizado */}
      <Box display="flex" flex="1">
        <Sidebar />
        <Box flex="1" p={4} ml={{ base: 0 }}>
          <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={4} mb={4}>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Stat>
                <StatLabel>Today's Moneys</StatLabel>
                <StatNumber>$53,000</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  55%
                </StatHelpText>
              </Stat>
            </Box>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Stat>
                <StatLabel>Today's Users</StatLabel>
                <StatNumber>2,300</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  5%
                </StatHelpText>
              </Stat>
            </Box>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Stat>
                <StatLabel>New Clients</StatLabel>
                <StatNumber>3,020</StatNumber>
                <StatHelpText>
                  <StatArrow type="decrease" />
                  14%
                </StatHelpText>
              </Stat>
            </Box>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Stat>
                <StatLabel>Total Sales</StatLabel>
                <StatNumber>$173,000</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  8%
                </StatHelpText>
              </Stat>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Built by Developers
              </Text>
              <Text>
                Purity UI Dashboard: From colors, cards, typography to complex elements, you will find the full documentation.
              </Text>
            </Box>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Work with the rockets
              </Text>
              <Text>
                Wealth creation is a revolutionary recent positive-sum game. It is all about who takes the opportunity first.
              </Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4} mt={8}>
            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Projects
              </Text>
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
                <Box>
                  <Text mb={2}>Purity UI Version</Text>
                  <Progress value={60} colorScheme="blue" size="sm" borderRadius="md" />
                </Box>
                <Box>
                  <Text mb={2}>Add Progress Track</Text>
                  <Progress value={10} colorScheme="cyan" size="sm" borderRadius="md" />
                </Box>
                <Box>
                  <Text mb={2}>Fix Platform Errors</Text>
                  <Progress value={100} colorScheme="green" size="sm" borderRadius="md" />
                </Box>
                <Box>
                  <Text mb={2}>Launch our Mobile App</Text>
                  <Progress value={100} colorScheme="green" size="sm" borderRadius="md" />
                </Box>
                <Box>
                  <Text mb={2}>Add the New Pricing Page</Text>
                  <Progress value={25} colorScheme="orange" size="sm" borderRadius="md" />
                </Box>
                <Box>
                  <Text mb={2}>Redesign New Online Shop</Text>
                  <Progress value={40} colorScheme="blue" size="sm" borderRadius="md" />
                </Box>
              </SimpleGrid>
            </Box>

            <Box bg={bg} p={4} borderRadius="md" boxShadow="md">
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Orders Overview
              </Text>
              <Box>
                <Text mb={2}>$2400, Design changes</Text>
                <Text fontSize="sm" color="gray.500">22 DEC 7:20 PM</Text>
              </Box>
              <Box>
                <Text mb={2}>New order #4219423</Text>
                <Text fontSize="sm" color="gray.500">21 DEC 11:21 PM</Text>
              </Box>
              <Box>
                <Text mb={2}>Server Payments for April</Text>
                <Text fontSize="sm" color="gray.500">21 DEC 9:28 PM</Text>
              </Box>
              <Box>
                <Text mb={2}>New card added for order #3210145</Text>
                <Text fontSize="sm" color="gray.500">20 DEC 3:52 PM</Text>
              </Box>
              <Box>
                <Text mb={2}>Unlock packages for Development</Text>
                <Text fontSize="sm" color="gray.500">19 DEC 11:35 PM</Text>
              </Box>
              <Box>
                <Text mb={2}>New order #9851258</Text>
                <Text fontSize="sm" color="gray.500">18 DEC 4:41 PM</Text>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
