import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import styles from './RulesPage.module.scss';
import { Button, Box, Flex, Image, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import afiliacao from '../../assets/regras/afiliacao.png';
import comoGanhar from '../../assets/regras/como-ganhar-guitar-coins.png';
import conclusao from '../../assets/regras/conclusao.png';
import perfil from '../../assets/regras/perfil.png';
import oqSao from '../../assets/regras/oq-sao-guitar-coins.png';

const regrasSteps = [
  { step: 1, title: 'O que são GC$?', description: 'GC$ (Guitar Coins) são pontos que podem ser convertidos em dinheiro real!', image: oqSao },
  { step: 2, title: 'Como ganhar Guitar Coins (GC$)?', description: [
      'Complete missões na aba missões',
      'Compre Guitar Coins',
      'Entre no top 10 e ganhe até 15 mil em recompensas',
      'PVP: Duele contra outros jogadores online, escolha sua música preferida, aposte um valor contra seu oponente e quem acertar mais notas leva a melhor!'
    ], image: comoGanhar },
  { step: 3, title: 'Afiliação', description: 'Todo jogador é um afiliado! Você pode indicar amigos, e sempre que eles comprarem Guitar Coins (GC$), você ganhará GC$ como recompensa pela sua indicação!', image: afiliacao },
  { step: 4, title: 'Perfil', description: 'Insira seus dados e, se quiser, adicione seu @ do Instagram. Quando estiver no top 10, milhares de pessoas poderão ver você!', image: perfil },
  { step: 5, title: 'Conclusão', description: 'Obrigado por estar aqui! Divirta-se jogando e, para começar, aqui estão 2 GC$ para tocar sua primeira música!', image: conclusao }
];

const RulesPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep < regrasSteps.length - 1 ? prevStep + 1 : prevStep));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  return (
    <DashboardLayout>
      <section className={styles['regras-container']}>
        <div className={styles['container']}>

          <div className={styles['step-card']}>
            <Box className={styles['step-content']}>
              <h3>{regrasSteps[currentStep].title}</h3>

              {/* Se o description for um array, renderizamos uma lista, caso contrário, mostramos o texto */}
              {Array.isArray(regrasSteps[currentStep].description) ? (
                <List spacing={3} textAlign="left" mt={4}>
                    {/* @ts-ignore */}
                  {regrasSteps[currentStep]?.description?.map((item, index) => (
                    <ListItem key={index} color="white">
                      <ListIcon as={CheckIcon} color="yellow.400" />
                      {item}
                    </ListItem>
                  ))}
                </List>
              ) : (
                <p>{regrasSteps[currentStep].description}</p>
              )}

              {/* Imagem do passo atual */}
              <Image className={styles['img-3d']} src={regrasSteps[currentStep].image} alt={regrasSteps[currentStep].title} mx="auto" my={4} />
            </Box>

            <div className={styles['step-actions']}>
              <Button onClick={prevStep} isDisabled={currentStep === 0}>
                Anterior
              </Button>
              <Button onClick={nextStep} isDisabled={currentStep === regrasSteps.length - 1} colorScheme="yellow">
                Próximo
              </Button>
            </div>

            {/* Bolinhas de Navegação */}
            <Flex justify="center" mb={4} mt={4}>
              {regrasSteps.map((_, index) => (
                <Box
                  key={index}
                  className={styles['step-dot']}
                  bg={currentStep === index ? 'yellow.500' : 'gray.400'}
                  w={4}
                  h={4}
                  borderRadius="50%"
                  mx={1}
                />
              ))}
            </Flex>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default RulesPage;
