import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Para redirecionar o usuário
import AppRoutes from './routes'; // Certifique-se de que o caminho para o arquivo de rotas está correto
import Swal from 'sweetalert2'; // Importando SweetAlert2
import axios from 'axios'; // Para obter o IP do usuário

function App() {
  const [userIP, setUserIP] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Função para obter o IP do usuário
    const getUserIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json'); // Usando API do ipify
        const userIP = response.data.ip;
        setUserIP(userIP);

        // Armazenar o IP no localStorage se não estiver lá
        if (!localStorage.getItem('userIP')) {
          localStorage.setItem('userIP', userIP);
        }
      } catch (error) {
        console.error('Erro ao obter o IP do usuário', error);
      }
    };

    // Contador de tentativas de burla
    const incrementViolationAttempts = () => {
      let attempts = localStorage.getItem('violationAttempts');
      if (!attempts) {
        attempts = '0';
      }
      const newAttempts = parseInt(attempts) + 1;
      localStorage.setItem('violationAttempts', newAttempts.toString());
      return newAttempts;
    };

    // Função para mostrar alerta de burla e redirecionar
    const showViolationAlert = () => {
      const attempts = incrementViolationAttempts();

      Swal.fire({
        title: 'Atenção!',
        text: `Se eu fosse você, não tentaria mais nada. Você já tentou burlar nossas regras ${attempts} vezes.`,
        icon: 'warning',
        confirmButtonText: 'Ok',
        background: '#17161A',
        color: '#fff',
        confirmButtonColor: '#FF0000',
      }).then(() => {
        if (attempts >= 2) { // Redirecionar após a segunda tentativa
          navigate('/violation', { state: { userIP } });
        }
      });
    };

    // Desabilitar F12, Ctrl+Shift+I, Ctrl+U, Ctrl+Shift+C, Ctrl+Shift+J, Ctrl+S
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && e.key === 'I') || // Ctrl + Shift + I (DevTools)
        (e.ctrlKey && e.key === 'U') || // Ctrl + U (View Source)
        (e.ctrlKey && e.shiftKey && e.key === 'C') || // Ctrl + Shift + C (Inspect Element)
        (e.ctrlKey && e.shiftKey && e.key === 'J') || // Ctrl + Shift + J (Console)
        (e.ctrlKey && e.key === 'S') // Ctrl + S (Save Page)
      ) {
        e.preventDefault();
        showViolationAlert();
        return false;
      }
    };

    // Desabilitar clique com o botão direito do mouse (menu de contexto)
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      showViolationAlert();
    };

    // Obter o IP do usuário ao carregar a página
    getUserIP();

    // Adicionar eventos
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('contextmenu', handleContextMenu);

    // Limpar eventos ao desmontar o componente
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [navigate, userIP]);

  return (
    <div className="App">
      <AppRoutes /> {/* Renderizando as rotas */}
    </div>
  );
}

export default App;
