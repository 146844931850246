import React from 'react';
import { Avatar, Box, Text, Heading, Button, Stack, Divider, SimpleGrid, VStack, Icon } from '@chakra-ui/react';
import DashboardLayout from '../../components/DashboardLayout';
import { useUser } from '../../contexts/userContext';  // Importando o contexto do usuário
import { FaMoneyBillWave, FaEnvelope, FaIdCard, FaPhone, FaCalendarAlt } from 'react-icons/fa';  // Ícones
import './Profile.scss';
import AutoPlayVideo from '../../components/AutoPlayVideo';
//@ts-ignore
import video from './bg.mp4';

const Profile = () => {
  const { user } = useUser(); // Obtendo os dados do usuário do contexto

  // Verificando se os dados do usuário estão disponíveis
  if (!user) {
    return <Text color="brand.mutedText">Carregando dados do usuário...</Text>;
  }

  return (
    <DashboardLayout>
      <div className="banner-profile">
        {/* Componente de vídeo com sobreposição */}
        <AutoPlayVideo src={video} className="background-video" />
        <div className="video-overlay" /> {/* Sobreposição preta */}

        <div className="avatar-container">
          <Avatar size="2xl" name={user.name} src={`https://i.pravatar.cc/?u=80`} />
        </div>
      </div>

      <Box className="page-container">
        <Box  className="content" mt={10} p={6} bg="black" borderRadius="lg" boxShadow="lg" maxW="800px" mx="auto">
          <Stack spacing={8} align="center">
            
            {/* Informações Pessoais */}
            <SimpleGrid columns={2} spacing={6} w="100%">
                {/* Saldo */}
                <Text color="white" fontSize="lg" display="flex" alignItems="center">
                  <Icon as={FaMoneyBillWave} mr={2} color="green.400" /> Saldo:
                </Text>
                <Text fontSize="lg" color="green.400" fontWeight="bold">R$ {(user.balance.amount / 100).toFixed(2)}</Text>

                {/* Número de Telefone */}
                <Text color="white" fontSize="lg" display="flex" alignItems="center">
                  <Icon as={FaPhone} mr={2} /> Telefone:
                </Text>
                <Text fontSize="lg" color="white">{user.phone}</Text>

                {/* Email */}
                <Text color="white" fontSize="lg" display="flex" alignItems="center">
                  <Icon as={FaEnvelope} mr={2} /> Email:
                </Text>
                <Text fontSize="lg" color="white">{user.email}</Text>

                {/* CPF */}
                <Text color="white" fontSize="lg" display="flex" alignItems="center">
                  <Icon as={FaIdCard} mr={2} /> CPF:
                </Text>
                <Text fontSize="lg" color="white">{user.cpf}</Text>

                {/* Data de Criação */}
                <Text color="white" fontSize="lg" display="flex" alignItems="center">
                  <Icon as={FaCalendarAlt} mr={2} /> Data de Criação:
                </Text>
                <Text fontSize="lg" color="white">{new Date(user.createdAt).toLocaleDateString()}</Text>
            </SimpleGrid>

            <Divider borderColor="#ffaa00" />

          </Stack>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Profile;
