import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Spinner,
  VStack,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import CustomButton from '../CustomButton/CustomButton';
import Timer from '../Timer';
import { NumericFormat } from 'react-number-format';

interface AddCreditsModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleGenerateQrCode: (value: number) => void;
  isLoading: boolean;
  qrCode: string | null;
  transactionId: string | null;
  checkPaymentStatus: (transactionId: string) => Promise<string>;
}

const AddCreditsModal: React.FC<AddCreditsModalProps> = ({
  isOpen,
  onClose,
  handleGenerateQrCode,
  isLoading,
  qrCode,
  transactionId,
  checkPaymentStatus,
}) => {
  const [rawValue, setRawValue] = useState<number | null>(null);
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('não pago');
  const [verificationAttempts, setVerificationAttempts] = useState(0);
  const toast = useToast();

  useEffect(() => {
    const verifyPaymentStatus = async () => {
      if (transactionId && verificationAttempts < 3) {
        const status = await checkPaymentStatus(transactionId);
        setPaymentStatus(status === 'PAID' ? 'pago' : 'não pago');

        if (status === 'PAID') {
          toast({
            title: 'Pagamento confirmado!',
            description: 'Obrigado pelo pagamento, você já pode usar seus créditos.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setIsQrCodeVisible(false);
          onClose();
        } else {
          setVerificationAttempts((prev) => prev + 1);
        }
      }
    };

    if (isQrCodeVisible && transactionId && verificationAttempts < 3) {
      const timer = setTimeout(verifyPaymentStatus, 20000);
      return () => clearTimeout(timer);
    }
  }, [transactionId, isQrCodeVisible, checkPaymentStatus, onClose, verificationAttempts, toast]);

  const handleCopyQrCode = () => {
    if (qrCode) {
      navigator.clipboard.writeText(qrCode);
      toast({
        title: 'QR Code copiado!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleGenerateNewQrCode = () => {
    setRawValue(null);
    setIsQrCodeVisible(false);
    setVerificationAttempts(0);
    handleGenerateQrCode(rawValue || 0);
  };

  const handleGenerate = () => {
    if (rawValue && rawValue >= 30) {
      handleGenerateQrCode(rawValue);
      setIsQrCodeVisible(true);
      setVerificationAttempts(0);
    } else {
      toast({
        title: 'Valor insuficiente!',
        description: 'O valor mínimo é 30 fichas. Mais barato que um PS5!',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#17161A" color="white">
        <ModalHeader>Adicionar Créditos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isQrCodeVisible && qrCode ? (
            <VStack spacing={4}>
              <Text>Escaneie o QR Code para adicionar créditos:</Text>
              <QRCode value={qrCode} size={200} />
              <Text>Transação: {transactionId}</Text>
              <Timer initialTime={60} onExpire={() => console.log('QR Code expirado!')} />
              <HStack spacing={4}>
                <CustomButton onClick={handleCopyQrCode}>Copiar QR Code</CustomButton>
                <CustomButton onClick={handleGenerateNewQrCode}>Gerar Novo QR Code</CustomButton>
              </HStack>
              <Text>Status do pagamento: {paymentStatus}</Text>
            </VStack>
          ) : (
            <VStack spacing={4}>
              <Text>Digite o valor que deseja adicionar (R$):</Text>
              <NumericFormat
                value={rawValue !== null ? rawValue.toString() : ''}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  setRawValue(floatValue ? Math.round(floatValue) : null);
                }}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                displayType="input"
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={Input}
                placeholder="Digite o valor"
                bg="#252429"
                color="white"
                borderColor="#2D2C32"
                _placeholder={{ color: '#A0AEC0' }}
              />
              {rawValue !== null && rawValue < 30 && (
               <>
                 <Text color="red.400">Valor mínimo: 30 Fichas</Text>
                 <Text color="red.400">* Mais barato do que qualquer console *</Text>
               </>
              )}
              {isLoading && <Spinner size="lg" />}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          {!isQrCodeVisible && (
            <CustomButton
              style={{ width: '50%', fontSize: '16px' }}
              onClick={handleGenerate}
              disabled={!rawValue || isLoading}
            >
              Gerar QR Code
            </CustomButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditsModal;
