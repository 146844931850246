import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Flex,
  Heading,
  Text,
  Divider,
  useToast,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/authService';
import ProfileBackground from '../assets/img/BgSignUp.png'; // Importe a imagem

const Cadastro = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // Adiciona o estado para isAdmin
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      const data = { 
        name, 
        email, 
        cpf, 
        phone, 
        username, 
        password,
        isAdmin, // Inclui isAdmin nos dados enviados
      };
      await register(data); // Faz a chamada à API com os dados do formulário
      toast({
        title: 'Cadastro realizado com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      navigate('/login'); // Redireciona para a página de login após o cadastro
    } catch (error) {
      toast({
        title: 'Erro ao fazer cadastro.',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex minH="100vh" align="center" justify="center" position="relative">
      {/* Imagem de fundo */}
      <Image
        src={ProfileBackground}
        alt="Background"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        zIndex="-1"
      />

      <Box
        w={{ base: 'full', md: '50%' }}
        maxW="md"
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
      >
        <Heading as="h2" size="xl" textAlign="center" mb={6}>
          Register With
        </Heading>

        <Divider mb={6} />

        <FormControl mb={4}>
          <FormLabel>Nome</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Email</FormLabel>
          <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>CPF</FormLabel>
          <Input value={cpf} onChange={(e) => setCpf(e.target.value)} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Telefone</FormLabel>
          <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Username</FormLabel>
          <Input value={username} onChange={(e) => setUsername(e.target.value)} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Senha</FormLabel>
          <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </FormControl>

        <FormControl mb={4}>
          <Checkbox
            isChecked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
            colorScheme="teal"
          >
            Admin
          </Checkbox>
        </FormControl>

        <Button
          colorScheme="teal"
          size="lg"
          w="full"
          isLoading={isLoading}
          onClick={handleRegister}
        >
          Cadastrar
        </Button>

        <Text mt={4} textAlign="center">
          Already have an account?{' '}
          <Button variant="link" colorScheme="teal" onClick={() => navigate('/login')}>
            Sign In
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default Cadastro;
