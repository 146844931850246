import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import BalanceCard from '../../components/BalanceCard/BalanceCard'; 
import { useUser } from '../../contexts/userContext';
import { formatCurrency } from '../../utils/utils';
import styles from './WithDrawPage.module.scss';
import api from '../../services/api';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const WithDrawPage = () => {
  const { user } = useUser();
  const [withdrawAmount, setWithdrawAmount] = useState<string>(''); 
  const [formattedWithdrawAmount, setFormattedWithdrawAmount] = useState(''); 
  const [withdrawMethod, setWithdrawMethod] = useState('phone'); 
  const [isLoading, setIsLoading] = useState(false); 
  const [withdrawals, setWithdrawals] = useState([]); 
  const [skip, setSkip] = useState(0); 
  const [take] = useState(10); 
  const [loadingTable, setLoadingTable] = useState(false); 
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchWithdrawals();
  }, [skip]); 

  const fetchWithdrawals = () => {
    setLoadingTable(true); 
    api.get('/user/withdraw/requests', {
      params: {
        skip,
        take,
      },
    })
      .then((res) => {
        setWithdrawals(res.data.data); 
        setLoadingTable(false); 
      })
      .catch((err) => {
        console.error('Erro ao buscar saques:', err);
        setLoadingTable(false); 
      });
  };

  const handleWithdrawAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^\d]/g, ''); 
    const amountInCents = parseInt(rawValue) || 0; 
    setWithdrawAmount(rawValue); 

    const formattedAmount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amountInCents / 100); 

    setFormattedWithdrawAmount(formattedAmount);
  };

  const sendWithDrawRequest = () => {
    setIsLoading(true); 
    const pixKey = withdrawMethod === 'cpf' ? user.cpf : user.phone;
    const amountInCents = parseInt(withdrawAmount); 

    api.post('/user/withdraw', {
      amount: amountInCents, 
      pixKey: pixKey,
    })
      .then((res) => {
        setIsLoading(false); 
        MySwal.fire({
          title: 'Saque solicitado com sucesso',
          icon: 'success',
          background: '#17161A',
          confirmButtonColor: '#FFA500',
          color: '#FFFFFF',
          iconColor: '#00ff00',
        });
        fetchWithdrawals(); 
      })
      .catch((err) => {
        setIsLoading(false); 
        MySwal.fire({
          title: 'Erro ao solicitar saque, verifique seu saldo e chave pix',
          icon: 'error',
          background: '#17161A',
          confirmButtonColor: '#FFA500',
          color: '#FFFFFF',
          iconColor: 'red',
        });
      });
  };

  // Função para traduzir e colorir o status
  const translateStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return { text: 'Pendente', color: '#f1c40f' }; // Amarelo
      case 'PAID':
        return { text: 'Pago', color: '#2ecc71' }; // Verde
      case 'CONFIRMED':
        return { text: 'Confirmado', color: '#3498db' }; // Azul
      case 'INSUFFICIENT_FUNDS':
        return { text: 'Fundos Insuficientes', color: '#e74c3c' }; // Vermelho
      default:
        return { text: status, color: '#fff' }; // Default branco se for outro status
    }
  };

  return (
    <DashboardLayout>
      <div className={styles['background-container']}>
        <div className={styles['withdraw-container']}>
          <div className={styles['grid-container']}>
            <div className={styles['balance-card']}>
              <BalanceCard
                title="Seu saldo total"
                amount={formatCurrency(user?.balance?.amount || 0)}
              />
            </div>

            <div className={styles['input-card']}>
              <select
                value={withdrawMethod}
                onChange={(e) => setWithdrawMethod(e.target.value)}
                className={styles['styled-select']}
              >
                <option value="cpf">CPF</option>
                <option value="phone">Telefone</option>
              </select>

              <input
                type="text"
                placeholder="Digite o valor do saque"
                value={formattedWithdrawAmount}
                onChange={handleWithdrawAmountChange}
                className={styles['styled-input']}
              />
              <button onClick={sendWithDrawRequest} disabled={isLoading}>
                {isLoading ? 'Processando...' : 'Confirmar Saque'}
              </button>
            </div>
          </div>

          {/* Row abaixo dos cards para listar os saques */}
          <div className={styles['table-container']}>
            {loadingTable ? (
              <div className={styles.loader}></div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Valor</th>
                    <th>Pix Key</th>
                    <th>Status</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawals.length > 0 ? (
                    withdrawals.map((withdrawal: any) => {
                      const { text, color } = translateStatus(withdrawal.status);
                      return (
                        <tr key={withdrawal.id}>
                          <td>{withdrawal.id}</td>
                          <td>{formatCurrency(withdrawal.amount)}</td>
                          <td>{withdrawal.pixKey}</td>
                          <td style={{ color }}>{text}</td> {/* Status traduzido e colorido */}
                          <td>{new Date(withdrawal.createdAt).toLocaleDateString()}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>Nenhum saque encontrado</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {/* Botões de paginação */}
            <div className={styles['pagination-container']}>
              <button
                onClick={() => setSkip(skip - take)}
                disabled={skip === 0}
              >
                Anterior
              </button>
              <button
                onClick={() => setSkip(skip + take)}
                disabled={withdrawals.length < take}
              >
                Próximo
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WithDrawPage;
