import React from 'react';
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Badge,
  Progress,
  IconButton,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiEdit2, FiMoreVertical } from 'react-icons/fi';
import Header from '../components/Header';  // Ajuste o caminho conforme necessário
import Sidebar from '../components/Sidebar';  // Ajuste o caminho conforme necessário

const Tables: React.FC = () => {
  const tableBg = useColorModeValue('white', 'gray.800');

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header /> {/* Header centralizado */}
      <Box display="flex" flex="1">
        <Sidebar />  {/* Sidebar */}
        <Box flex="1" p={4}>
          <Box p={4}>
            {/* Authors Table */}
            <Box
              bg={tableBg}
              borderRadius="lg"
              boxShadow="md"
              p={6}
              mb={8}
            >
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Authors Table
              </Text>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Author</Th>
                    <Th>Function</Th>
                    <Th>Status</Th>
                    <Th>Employed</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[
                    {
                      name: "Esthera Jackson",
                      email: "alexa@simmmp.com",
                      avatar: "https://i.pravatar.cc/150?img=1",
                      function: "Organization",
                      status: "Online",
                      employed: "14/06/21",
                    },
                    {
                      name: "Alexa Liras",
                      email: "laurent@simmmp.com",
                      avatar: "https://i.pravatar.cc/150?img=2",
                      function: "Developer",
                      status: "Offline",
                      employed: "12/05/21",
                    },
                    {
                      name: "Laurent Michael",
                      email: "laurent@simmmp.com",
                      avatar: "https://i.pravatar.cc/150?img=3",
                      function: "Projects",
                      status: "Online",
                      employed: "07/06/21",
                    },
                    {
                      name: "Fredurado Hill",
                      email: "freduardo@simmmp.com",
                      avatar: "https://i.pravatar.cc/150?img=4",
                      function: "Organization",
                      status: "Online",
                      employed: "14/11/21",
                    },
                    {
                      name: "Daniel Thomas",
                      email: "daniel@simmmp.com",
                      avatar: "https://i.pravatar.cc/150?img=5",
                      function: "Developer",
                      status: "Offline",
                      employed: "21/01/21",
                    },
                    {
                      name: "Mark Wilson",
                      email: "mark@simmmp.com",
                      avatar: "https://i.pravatar.cc/150?img=6",
                      function: "UI/UX Design",
                      status: "Offline",
                      employed: "04/09/20",
                    },
                  ].map((author, index) => (
                    <Tr key={index}>
                      <Td>
                        <Flex align="center">
                          <Avatar size="md" name={author.name} src={author.avatar} mr={4} />
                          <Box>
                            <Text fontWeight="bold">{author.name}</Text>
                            <Text fontSize="sm" color="gray.500">{author.email}</Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td>
                        <Box>
                          <Text fontWeight="bold">{author.function}</Text>
                          <Text fontSize="sm" color="gray.500">Manager</Text>
                        </Box>
                      </Td>
                      <Td>
                        <Badge colorScheme={author.status === "Online" ? "green" : "gray"}>
                          {author.status}
                        </Badge>
                      </Td>
                      <Td>{author.employed}</Td>
                      <Td>
                        <IconButton
                          aria-label="Edit"
                          icon={<FiEdit2 />}
                          variant="ghost"
                          size="sm"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>

            {/* Projects Table */}
            <Box
              bg={tableBg}
              borderRadius="lg"
              boxShadow="md"
              p={6}
            >
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Projects Table
              </Text>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Companies</Th>
                    <Th>Budget</Th>
                    <Th>Status</Th>
                    <Th>Completion</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[
                    {
                      company: "Purity UI Version",
                      budget: "$14,000",
                      status: "60%",
                      color: "blue",
                      icon: "https://via.placeholder.com/50?text=XD"
                    },
                    {
                      company: "Add Progress Track",
                      budget: "$3,000",
                      status: "10%",
                      color: "cyan",
                      icon: "https://via.placeholder.com/50?text=AT"
                    },
                    {
                      company: "Fix Platform Errors",
                      budget: "Not set",
                      status: "100%",
                      color: "green",
                      icon: "https://via.placeholder.com/50?text=FT"
                    },
                    {
                      company: "Launch our Mobile App",
                      budget: "$32,000",
                      status: "100%",
                      color: "green",
                      icon: "https://via.placeholder.com/50?text=LA"
                    },
                    {
                      company: "Add the New Pricing Page",
                      budget: "$400",
                      status: "25%",
                      color: "orange",
                      icon: "https://via.placeholder.com/50?text=PP"
                    },
                    {
                      company: "Redesign New Online Shop",
                      budget: "$7,600",
                      status: "40%",
                      color: "blue",
                      icon: "https://via.placeholder.com/50?text=OS"
                    },
                  ].map((project, index) => (
                    <Tr key={index}>
                      <Td>
                        <Flex align="center">
                          <Avatar size="md" name={project.company} src={project.icon} mr={4} />
                          <Box>
                            <Text fontWeight="bold">{project.company}</Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td>{project.budget}</Td>
                      <Td>
                        <Text>{project.status}</Text>
                      </Td>
                      <Td>
                        <Progress
                          value={parseInt(project.status)}
                          colorScheme={project.color}
                          size="sm"
                          borderRadius="md"
                        />
                      </Td>
                      <Td>
                        <IconButton
                          aria-label="More Options"
                          icon={<FiMoreVertical />}
                          variant="ghost"
                          size="sm"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Tables;
