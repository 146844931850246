import React from 'react';
import { Box, Flex, IconButton, Avatar, Menu, MenuButton, MenuList, MenuItem, Image } from '@chakra-ui/react';
import { FaBell, FaCog } from 'react-icons/fa';
import LogoImg from "../assets/logo.png";

const Header = () => {
  return (
    <Box
      as="header"
      bg="#17161A"  // Cor do fundo do header
      px={4}
      py={3}
      boxShadow="md"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Logo da Aplicação */}
      <Image src={LogoImg} alt="Logo" boxSize="90px" />

      {/* Área de Ações e Menu */}
      <Flex alignItems="center">
        {/* <IconButton
          icon={<FaBell />}
          aria-label="Notifications"
          variant="ghost"
          color="white" 
          mr={2}
        />
        <IconButton
          icon={<FaCog />}
          aria-label="Settings"
          variant="ghost"
          color="white" 
          mr={2}
        /> */}
        <Menu>
          <MenuButton as={IconButton} aria-label="User Menu" bg="#17161A">
            <Avatar size="sm" name="UserTest02" src="https://i.pravatar.cc/150?img=13" />
          </MenuButton>
          <MenuList bg="#17161A" borderColor="white" zIndex={999}>
            {/* <MenuItem _hover={{ bg: 'gray.700' }} color="white" bg="#17161A">
              Perfil
            </MenuItem>
            <MenuItem _hover={{ bg: 'gray.700' }} color="white" bg="#17161A">
              Configurações
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                localStorage.clear()
                window.location.reload()
              }}
              _hover={{ bg: 'gray.700' }} color="white" bg="#17161A">
              Sair
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export default Header;
