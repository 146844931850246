import React, { useState, useEffect } from 'react';
import { FaGuitar } from 'react-icons/fa'; // Importando ícone de guitarra
import { useNavigate } from 'react-router-dom'; // Para redirecionar o usuário
import DashboardLayout from '../../components/DashboardLayout'; // Layout de dashboard
import styles from './DemoGamePage.module.scss';

const DemoGamePage = () => {
    const [isLoading, setIsLoading] = useState(true); // Estado de loading
    const navigate = useNavigate(); // Hook para navegação

    // Simulando o loading de 5 segundos antes de exibir o iframe
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000); // 5 segundos de loading

        return () => clearTimeout(timer); // Cleanup do timeout
    }, []);

    // Redireciona o usuário após 1 minuto
    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            navigate('/result?pontos=12502156&accuracy=100&totalEarned=100');
        }, 60000); // 1 minuto

        return () => clearTimeout(redirectTimer); // Cleanup do timeout
    }, [navigate]);

    return (
        <DashboardLayout>
            <div className={styles['demo-container']}>


                {isLoading ? (
                    <>
                        <div className={styles['text-container']}>
                            <h1 className={styles['animated-text']}>O Palco Está Montado!</h1>
                            <p className={styles['animated-subtext']}>
                                O jogo da guitarrinha Abrasileirado que você ama VOLTOU ! E agora com um desafio especial para você !
                            </p>
                        </div>
                        <div className={styles['loading-text']}>Carregando o palco... Prepare-se!</div> 

                    </>
                ) : (
                    <div className={styles['iframe-container']}>
                        <iframe
                            src="https://guitar-game1.onrender.com/demo"
                            title="Demo Game"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        ></iframe>
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
};

export default DemoGamePage;
