import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { NumericFormat } from 'react-number-format';

interface PlayModalProps {
  isOpen: boolean;
  onClose: () => void;
  song: { id: number; title: string; image: string | null };
  onPlay: (songId: number, betValue: number) => Promise<void>;
}

const PlayModal: React.FC<PlayModalProps> = ({ isOpen, onClose, song, onPlay }) => {
  const [betValue, setBetValue] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (values: any) => {
    const { floatValue } = values;
    if (floatValue !== undefined) {
      setBetValue(values.formattedValue);
    } else {
      setBetValue('');
    }
  };

  const handlePlay = async () => {
    setIsLoading(true);
    const betValueAsNumber = parseFloat(betValue.replace(/[^0-9,]+/g, '').replace(',', '.')); // Converte o valor para número inteiro
    await onPlay(song.id, betValueAsNumber * 100); // Envia o valor como inteiro
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent bg="#17161A" color="#FFFFFF">
        <ModalHeader textAlign="center">Música: <b>{song.title}</b></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            {song.image && (
              <Image src={song.image} alt={song.title} boxSize="150px" borderRadius="md" />
            )}
            <Text fontSize="lg" textAlign="center">
              Fichas a jogar (GC$):
            </Text>
            <NumericFormat
              value={betValue}
              onValueChange={handleInputChange}
              thousandSeparator="."
              decimalSeparator=","
              prefix="GC$ "
              displayType="input"
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              customInput={Input}
              bg="#252429"
              color="#FFFFFF"
              borderColor="#2D2C32"
              _placeholder={{ color: '#A0AEC0' }}
            />
            <Button
              colorScheme="teal"
              size="md"
              width="100%"
              onClick={handlePlay}
              mt={4}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Jogar Agora
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlayModal;
