import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ViolationPage.scss'; // Assumindo que o estilo já está implementado

const ViolationPage = () => {
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const { state } = location as any;

  // Gerar um IP fictício se o real não estiver disponível
  const generateFakeIP = () => {
    return `${Math.floor(Math.random() * 255)}.${Math.floor(Math.random() * 255)}.${Math.floor(
      Math.random() * 255
    )}.${Math.floor(Math.random() * 255)}`;
  };

  // Usar o IP real do estado, se disponível, senão gerar um fictício
  const userIP = state?.userIP || generateFakeIP();

  useEffect(() => {
    // Função para capturar a imagem da câmera
    const captureScreenshot = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const video = document.createElement('video');
        video.srcObject = stream;

        video.onloadedmetadata = () => {
          video.play();
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const dataURL = canvas.toDataURL('image/png');
            setScreenshot(dataURL); // Armazenar a captura de tela
          }

          // Parar o stream da câmera para evitar que continue "piscando"
          stream.getTracks().forEach((track) => track.stop());
          video.pause(); // Pausar o vídeo para evitar o piscar
          setLoading(false); // Remover o loading após a captura da imagem
        };
      } catch (err) {
        console.error('Erro ao acessar a câmera:', err);

        // Se não conseguirmos acessar a câmera, vamos gerar uma captura fictícia (opcional)
        setScreenshot('/path/to/fake-image.png'); // Caminho para uma imagem fictícia
        setLoading(false);
      }
    };

    // Capturar a imagem uma única vez quando o componente for montado
    captureScreenshot();
  }, []);

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Carregando... Por favor, permita o acesso à câmera.</p>
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="violation-container">
      <h1 className="hacker-title">DIGA X, SORRIA, OTÁRIO!</h1>
      <p className="user-ip">Endereço de Rede: <span>{userIP}</span></p>
      {screenshot && (
        <div className="screenshot-container">
          <h3>Captura da Câmera:</h3>
          <img src={screenshot} alt="Captura de Tela da Câmera" />
        </div>
      )}
    </div>
  );
};

export default ViolationPage;
