import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Remover o BrowserRouter
import Home from '../pages/Home';
import Login from '../pages/Login/Login';
import Cadastro from '../pages/Cadastro';
import Billing from '../pages/Billing';
import Profile from '../pages/Profile/Profile';
import PrivateRoute from './PrivateRoute';
import Tables from '../pages/tables';
import Dashboard from '../pages/Dashboard';
import Withdraw from '../pages/withdraw';
import BillingPìx from '../pages/pix';
import Register from '../pages/Register/Register';
import PlayPage from '../pages/Play/PlayPage';
import Afiliation from '../pages/Afiliation/Afiliation';
import Ranking from '../pages/Ranking/Ranking';
import RulesPage from '../pages/RulesPage/RulesPage';
import ResultPage from '../pages/ResultPage/ResultPage';
import WithDrawPage from '../pages/WithDrawPage/WithDrawPage';
import DemoGamePage from '../pages/DemoGamePage/DemoGamePage';
import ViolationPage from '../pages/ViolationPage/ViolationPage';
import TermsOfUse from '../pages/TermsOfUse/TermsOfUse';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/demo" element={<DemoGamePage />} />
      <Route path="/violation" element={<ViolationPage />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/result" element={<ResultPage />} />
      <Route path="/term" element={<TermsOfUse />} />

      <Route path="/billing" element={<PrivateRoute element={Billing} />} />
      <Route path="/pix" element={<PrivateRoute element={BillingPìx} />} />
      <Route path="/profile" element={<PrivateRoute element={Profile} />} />
      <Route path="/play" element={<PrivateRoute element={PlayPage} />} />
      <Route path="/tables" element={<PrivateRoute element={Tables} />} />
      <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
      <Route path="/affiliation" element={<PrivateRoute element={Afiliation} />} />
      <Route path="/rank" element={<PrivateRoute element={Ranking} />} />
      <Route path="/rules" element={<PrivateRoute element={RulesPage} />} />
    
      <Route path="/withdraw" element={<PrivateRoute element={WithDrawPage} />} />
    </Routes>
  );
}

export default AppRoutes;
