import React from 'react';
import './CustomButton.scss';

interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean; // Adicionar a propriedade isLoading
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, className, isLoading, ...props }) => {
  return (
    <button className={`custom-button ${className}`} {...props} disabled={isLoading}>
      {isLoading ? (
        <div className="spinner"></div> // Spinner para indicar carregamento
      ) : (
        children
      )}
    </button>
  );
};

export default CustomButton;
