import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';
import './SquareCard.scss';

interface SquareCardProps {
  children: React.ReactNode;
  minWidth?: string;
  minHeight?: string;
  className?: string;  // Adicione esta linha
}

const SquareCard: React.FC<SquareCardProps> = ({ children, minWidth = '300px', minHeight = '200px', className }) => {
  const styles = useStyleConfig('SquareCard');

  return (
    <Box __css={styles} minWidth={minWidth} minHeight={minHeight} className={`square-card ${className}`}>
      {children}
    </Box>
  );
};

export default SquareCard;
