import React from 'react';
import { Box, VStack, HStack, Image, Text, Heading, Button, useBreakpointValue } from '@chakra-ui/react';
import { BsPlayCircle, BsFillLockFill, BsLock } from 'react-icons/bs';
import styles from './PlayPage.module.scss'; // Importando o styles do PlayPage

interface Music {
  id: number;
  title: string;
  artist: string;
  album: string;
  image: string | null;
  playButton: boolean;
}

interface MusicListProps {
  musicData: Music[];
  onPlaySong: (song: Music) => void;
}

const MusicList: React.FC<MusicListProps> = ({ musicData, onPlaySong }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg={'#17161A'} className={styles['music-list']} p={6} flex="1" boxShadow="lg" textAlign="center">
      {/* Título da lista */}
      <Heading size="md" mb={4} color="#FFFFFF">
        Lista de músicas
      </Heading>

      {/* Conteúdo da lista com rolagem */}
      <Box className={styles['list-musics']} overflowY="auto" width="100%">
        <VStack width="100%" spacing={4} align="center" justify="center">
          {musicData.map((music) => (
            <div key={music.id} className={styles['card-music']} style={{ width: '100%', maxWidth: '400px' }}>
              <Box className={styles['card-content']} p={4}>
                <HStack className={styles['card-header']} spacing={4} alignItems="center" justifyContent="center">
                  {music.image ? (
                    <Image src={music.image} boxSize="130px" className={styles['card-image']} borderRadius="md" />
                  ) : (
                    <Box display="flex" alignItems="center" justifyContent="center" boxSize="50px" borderRadius="md" className={styles['card-image-placeholder']}>
                      <BsFillLockFill size="24px" color="#fff" />
                    </Box>
                  )}
                  <Box flex="1" textAlign="left">
                    <Text className={styles['card-title']} fontWeight="bold">{music.title}</Text>
                    <Text className={styles['card-subtitle']}>{music.artist} - Álbum: {music.album}</Text>
                    <Button
                      onClick={() => onPlaySong(music)}
                      variant="ghost"
                      size="sm"
                      isDisabled={!music.playButton}
                      leftIcon={music.playButton ? <BsPlayCircle /> : <BsLock />}
                      className={styles['card-button']}
                      mt={4}
                      alignSelf={isMobile ? 'center' : 'flex-end'}
                    >
                      {music.playButton ? 'Jogar Agora' : 'Bloqueado'}
                    </Button>
                  </Box>
                </HStack>

              </Box>
            </div>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default MusicList;
