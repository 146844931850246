import api from './api';

interface LoginData {
  email: string;
  password: string;
}

interface RegisterData {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  username: string;
  password: string;
}

export const login = async (data: LoginData) => {
  try {
    const response = await api.post('/user/login', data);
    return response.data;
  } catch (error) {
    throw new Error('Erro ao fazer login. Verifique suas credenciais.');
  }
};

export const register = async (data: RegisterData) => {
  try {
    const response = await api.post('/user/create', data);
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error('Erro ao fazer cadastro. Verifique os dados e tente novamente.');
  }
};
