import {useState} from 'react';
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { FaEdit } from 'react-icons/fa';
import DashboardLayout from '../components/DashboardLayout';

const BillingPìx = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const tableSize = useBreakpointValue({ base: 'sm', md: 'md' });

  const handleCreateCharge = () => {
    setIsLoading(true);
    onOpen();

    setTimeout(() => {
      setIsLoading(false);
      setIsQRCodeVisible(true);
    }, 2000); // Simula o tempo de carregamento de 2 segundos
  };

  return (
    <DashboardLayout>
      <Box p={8}>
        <Heading mb={6}>Depósito</Heading>

        {/* Seção de Depósito */}
        <Box bg="white" borderRadius="md" boxShadow="md" p={6} mb={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input placeholder="Nome" />
            </FormControl>

            <FormControl>
              <FormLabel>CPF</FormLabel>
              <InputMask mask="999.999.999-99" placeholder="CPF">
               {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </FormControl>

            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input type="email" placeholder="Email" />
            </FormControl>

            <FormControl>
              <FormLabel>Tipo de Pagamento</FormLabel>
              <Select placeholder="Selecione o tipo">
                <option value="pix">PIX</option>
                <option value="cartao">Cartão</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Valor</FormLabel>
              <InputMask
                mask="R$ 999.999,99"
                maskChar=""
                placeholder="Valor da cobrança"
              >
                {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </FormControl>

            <Flex align="center" justify="flex-end">
              <Button colorScheme="teal" mt={6} w="full" onClick={handleCreateCharge}>
                Criar Cobrança
              </Button>
            </Flex>
          </SimpleGrid>
        </Box>

        {/* Seção de Histórico de Cobranças */}
        <Heading mb={4}>Histórico de Cobranças</Heading>
        <Box bg="white" borderRadius="md" boxShadow="md" p={6}>
          <Table variant="simple" size={tableSize}>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Valor</Th>
                <Th>Data</Th>
                <Th>Tipo</Th>
                <Th>Status</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>#1</Td>
                <Td>R$300,00</Td>
                <Td>14/08/2024</Td>
                <Td>PIX</Td>
                <Td>Pago</Td>
                <Td>
                  <Button size="sm" leftIcon={<FaEdit />}>
                    Editar
                  </Button>
                </Td>
              </Tr>
              <Tr>
                <Td>#2</Td>
                <Td>R$150,00</Td>
                <Td>13/08/2024</Td>
                <Td>Cartão</Td>
                <Td>Pendente</Td>
                <Td>
                  <Button size="sm" leftIcon={<FaEdit />}>
                    Editar
                  </Button>
                </Td>
              </Tr>
              {/* Adicione mais linhas conforme necessário */}
            </Tbody>
          </Table>
        </Box>
      </Box>

      {/* Modal para QR Code */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cobrança Criada</ModalHeader>
          <ModalBody>
            {isLoading ? (
              <Flex justify="center" align="center" h="100px">
                <Spinner size="xl" />
              </Flex>
            ) : isQRCodeVisible ? (
              <VStack spacing={4}>
                <Image
                  src="https://via.placeholder.com/150"
                  alt="QR Code"
                  boxSize="150px"
                  borderRadius="md"
                />
                <Text fontSize="xl">Valor: R$300,00</Text>
                <Text>Status: Aguardando Pagamento</Text>
              </VStack>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DashboardLayout>
  );
};

export default BillingPìx;