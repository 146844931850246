import { useUser } from "../../contexts/userContext";
import { formatCurrency } from "../../utils/utils";
import { Text, Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Heading, Spinner } from "@chakra-ui/react";
import { useState } from "react";

const WelcomeBox: React.FC<any> = ({ onAddCreditsClick }) => {
  const { user } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoading = !user?.balance?.amount;
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Box bg="#17161A" borderRadius="md" p={4} mb={4} boxShadow="lg">
      <VStack align="start" spacing={3} py={4}>
        <Text fontSize="lg" color="#FFFFFF">Bem-vindo(a)</Text>
        <Heading fontSize="2xl" color="#FFFFFF">{user?.name || 'Carregando...'}</Heading>
        <Box bg="#1F2937" borderRadius="md" p={3} w="full" py={4}>
          <Text fontSize="md" color="#FFFFFF">Seus Guitar Coins</Text>
          <Heading fontSize="2xl" color="#10B981">
            {isLoading ? ( // Exibe o spinner enquanto o balance não está disponível
              <Spinner size="lg" color="#10B981" />
            ) : (
              formatCurrency(user.balance.amount)
            )}
          </Heading>
        </Box>
        <Text fontSize="sm" color="#BBBBBB">
          {`Você está prestes a fazer parte de algo incrível. Mais que um jogo, é uma chance de reviver a sensação de estar no controle da guitarra.`}
          <Button variant="link" colorScheme="yellow" onClick={openModal}>
            Saiba mais
          </Button>
        </Text>
        <Button
          bg="#0e0e10"
          border="solid 1px #ffcc00"
          color="#ffcc00"
          borderRadius="full"
          boxSize="60px"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.3)"
          size="sm"
          w="full"
          colorScheme="green"
          onClick={onAddCreditsClick}>
          Comprar Guitar Coins
        </Button>
      </VStack>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent bg="#17161A" color="white" py={4}>
          <ModalHeader>Mais sobre o nosso jogo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md">
              <strong>🪙 O que são Guitar Coins?</strong>
              <br />
              Guitar Coins são fichas que te colocam no coração de uma experiência nostálgica e divertida. Cada jogo que você faz mantém nosso sonho vivo.
              <br />
              <br />
              <hr />
              <br />
              <strong>👨‍💻 Nossa Equipe</strong>
              <br />
              Somos desenvolvedores apaixonados, sem grandes recursos, apenas determinação de trazer o "Guitar Hero" brasileiro para você. Agora, qualquer celular pode ser sua guitarra!
              <br />
              <br />
              <hr />
              <br />
              <strong>🤗 Apoie Nosso Sonho</strong>
              <p>
                Ao comprar Guitar Coins, você não está apenas adquirindo fichas — você está nos ajudando a transformar um sonho em realidade. Cada moeda é um passo para reviver as músicas que marcaram nossas vidas, e você faz parte disso.
              </p>
              <p>
                Nosso objetivo é que todos possam jogar, sem a necessidade de consoles caros, apenas com um celular e uma conexão 4G e satélite sem custos. Com a sua ajuda, levaremos a alegria da música a todos.
              </p>

              <br />
              <hr />
              <br />
              <strong>🆘 Nos Ajude a Reviver o Passado</strong>
              <p>
                Lembra das horas que você passava jogando Guitar Hero? Queremos trazer isso de volta, de uma forma especial e abrasileirada. Estamos perto de realizar esse sonho, e sua ajuda é essencial para isso acontecer.
              </p>

              <br />
              <hr />
              <br />
              <strong>💀 Aviso de Segurança</strong>
              <p>
                Tentativas de hackear ou prejudicar nossos sistemas serão severamente punidas de formas inemagináveis e os que tentarem serão usados de exemplo . Nossa segurança cibernética é avançada e implacável. <br/> Se estiver duvidando, vamos brincar?
              </p>

            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" mr={3} onClick={closeModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WelcomeBox;
