import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api'; // Certifique-se de importar seu arquivo de configuração da API

interface UserContextData {
  user: User | null;
  fetchUserData: () => void;
}

interface User {
  id: number;
  email: string;
  name: string;
  cpf: string;
  phone: string;
  username: string;
  indicationId: string;
  balance: {
    amount: number;
  };
  isAdmin: boolean | null;
  createdAt?: Date
}

const UserContext = createContext<UserContextData | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await api.get('/user/info', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } else {
        throw new Error('Token não encontrado');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do usuário', error);
    }
  };

  useEffect(() => {
    fetchUserData(); // Chama a função para buscar os dados do usuário ao carregar o componente
  }, []);

  return (
    <UserContext.Provider value={{ user, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser deve ser usado dentro de um UserProvider');
  }
  return context;
};
