import React, { useRef, useEffect } from 'react';

interface AutoPlayVideoProps {
  src: string;
  type?: string;
  className?: string;
}

const AutoPlayVideo: React.FC<AutoPlayVideoProps> = ({ src, type = 'video/mp4', className }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Função para reiniciar o vídeo quando terminar
      const handleVideoEnd = () => {
        videoElement.currentTime = 0;
        videoElement.play();
      };

      // Iniciar o vídeo automaticamente
      videoElement.play();

      // Adicionar o event listener para o fim do vídeo
      videoElement.addEventListener('ended', handleVideoEnd);

      return () => {
        // Remover o event listener ao desmontar o componente
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, []);

  return (
    <video ref={videoRef} className={className} autoPlay muted playsInline loop>
      <source src={src} type={type} />
      Your browser does not support the video tag.
    </video>
  );
};

export default AutoPlayVideo;
