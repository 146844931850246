import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import DashboardLayout from '../../components/DashboardLayout';
import styles from './TermsOfUse.module.scss'; // Importando o CSS Module

const TermsOfUse = () => {
  return (
    <DashboardLayout>
      <div className={styles['background-container']}>
        <div className={styles['terms-container']}>
          <h1>Termos de Uso</h1>
          <ul>
            <li>O jogo Guitarrinha não é uma casa de apostas.</li>
            <li>Nosso jogo é totalmente baseado em doações de jogadores e patrocinadores.</li>
            <li>Guitarrinha não promove o vício em jogos e aposta.</li>
            <li>Os prêmios no jogo são simbólicos e não possuem valor monetário real.</li>
            <li>Todos os jogadores devem ter mais de 18 anos para participar.</li>
            <li>As doações feitas ao jogo são voluntárias e não garantem privilégios especiais.</li>
            <li>Não é permitido o uso de cheats, bots ou qualquer software de automação.</li>
            <li>O comportamento tóxico ou abusivo dentro do jogo resultará em banimento imediato.</li>
            <li>O jogo não se responsabiliza por perdas financeiras de qualquer natureza.</li>
            <li>Os dados pessoais dos jogadores são protegidos conforme nossa política de privacidade.</li>
            <li>O jogo se reserva o direito de alterar as regras a qualquer momento sem aviso prévio.</li>
            <li>As recompensas dentro do jogo são obtidas através de performance e atividades realizadas no jogo.</li>
            <li>Não somos responsáveis por falhas técnicas em dispositivos de terceiros.</li>
            <li>A venda ou troca de contas é proibida.</li>
            <li>As doações são não-reembolsáveis.</li>
            <li>Todos os jogadores devem seguir as leis locais ao jogar Guitarrinha.</li>
            <li>Qualquer tentativa de fraude resultará no cancelamento da conta.</li>
            <li>O jogo se reserva o direito de encerrar qualquer conta a qualquer momento por violação dos termos.</li>
            <li>Ao criar uma conta, o jogador aceita todos os termos de uso aqui descritos.</li>
            <li>O Guitarrinha é oferecido "como está" sem garantias de qualquer tipo.</li>
            <li>Não somos responsáveis por interrupções de serviço causadas por terceiros.</li>
            <li>Os itens do jogo são propriedade exclusiva da empresa desenvolvedora.</li>
            <li>O suporte ao cliente é oferecido conforme disponibilidade de horário.</li>
            <li>O jogador é responsável pela segurança de sua própria conta e senha.</li>
            <li>Não nos responsabilizamos por transações não autorizadas feitas por menores de idade.</li>
            <li>Os pontos ganhos no jogo não podem ser convertidos em dinheiro real.</li>
            <li>Guitarrinha não oferece suporte para modificação do jogo por terceiros.</li>
            <li>O uso de VPN para acessar o jogo pode resultar em banimento.</li>
            <li>As recompensas por doações são meramente decorativas e não influenciam o jogo.</li>
            <li>O jogo pode conter conteúdo gerado pelos usuários, que será monitorado e moderado.</li>
            <li>As regras do jogo podem variar conforme a região.</li>
            <li>Os jogadores podem ser removidos de competições por comportamento inadequado.</li>
            <li>Todo o conteúdo dentro do jogo é protegido por direitos autorais.</li>
            <li>O jogo não endossa ou se responsabiliza por produtos ou serviços anunciados por terceiros.</li>
            <li>A conta do jogador pode ser suspensa por inatividade prolongada.</li>
            <li>Qualquer violação dos termos pode resultar em penalidades dentro do jogo.</li>
            <li>As atualizações do jogo podem alterar o funcionamento de certos recursos.</li>
            <li>Os jogadores não podem reivindicar qualquer propriedade intelectual sobre o jogo.</li>
            <li>O uso de linguagens ofensivas no chat resultará em ações disciplinares.</li>
            <li>O jogo coleta dados estatísticos anônimos para melhorar a experiência do usuário.</li>
            <li>As campanhas de doação podem ser alteradas ou canceladas sem aviso prévio.</li>
            <li>O suporte técnico para o jogo está limitado a sistemas operacionais específicos.</li>
            <li>Não garantimos que o jogo será compatível com todos os dispositivos.</li>
            <li>Os jogadores são incentivados a jogar de maneira responsável.</li>
            <li>A decisão da administração é final e inquestionável em todas as disputas.</li>
            <li>As doações feitas pelos jogadores não garantem qualquer vantagem competitiva.</li>
            <li>O jogo pode ser interrompido ou descontinuado sem aviso prévio.</li>
          </ul>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TermsOfUse;
